<template>
  <v-main>
    <v-container fluid page class="mb-10">
      <v-form ref="form">
        <v-row align="center">
          <v-col cols="12 d-flex align-baseline justify-space-between">
            <h2 class="display-1 font-weight-light pt-5 pl-5">Facturas</h2>
          </v-col>

          <v-col cols="12" v-if="error">
            <v-alert border="left" colored-border type="error" elevation="2">
              <strong v-if="code">{{ code }}</strong>
              {{ message }}
            </v-alert>
          </v-col>

          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-row no-gutters class="grey--text text--darken-4">
                  <v-col
                    sm="1"
                    class="subtitle-1 text-truncate d-none d-sm-flex"
                  ></v-col>
                  <v-col class="text-truncate d-flex"> Fecha </v-col>
                  <v-col md="2" class="text-truncate d-none d-md-flex">
                    Código Factura
                  </v-col>
                  <v-col
                    cols="3"
                    sm="2"
                    class="text-truncate d-flex justify-end"
                  >
                    Importe
                  </v-col>
                  <v-col class="d-flex justify-end pr-5"> Opciones </v-col>
                </v-row>

                <v-row
                  no-gutters
                  align="center"
                  class="grey--text text--darken-4"
                  v-for="invoice in invoices"
                  :key="invoice.uuid"
                >
                  <v-col sm="1" class="text-truncate d-none d-sm-flex">
                    <v-avatar>
                      <v-icon>mdi-cloud-download</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col class="text-truncate d-none d-sm-flex">
                    {{ formatTimestamp(invoice.created) }}
                  </v-col>
                  <v-col class="text-truncate d-sm-none d-flex">
                    {{ formatTimestampSmall(invoice.created) }}
                  </v-col>
                  <v-col md="2" class="text-truncate d-none d-md-flex">
                    {{ invoice.number }}
                  </v-col>

                  <v-col
                    cols="3"
                    sm="2"
                    class="text-truncate d-flex justify-end"
                  >
                    {{ invoice.amount_due | money }}
                  </v-col>

                  <v-col class="d-flex justify-end">
                    <v-btn text color="primary" @click="goToStripe(invoice)">
                      Descargar
                    </v-btn>
                  </v-col>
                </v-row>

                <v-col cols="12" class="px-0" v-if="invoices.length == 0">
                  <v-alert
                    border="left"
                    colored-border
                    type="info"
                    elevation="2"
                  >
                    No hay ninguna factura actualmente en el sistema.
                  </v-alert>
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <!-- Loading screen -->
    <eximyr-loading :loading="loading"></eximyr-loading>
  </v-main>
</template>

<script>
import errors from '../mixins/errors';
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';

import EximyrLoading from '../components/EximyrLoading';

export default {
  mixins: [errors],

  components: {
    EximyrLoading,
  },

  data: () => ({
    invoices: [],

    dialog: false,

    loading: false,
  }),

  computed: {
    ...mapGetters('security', {
      isAdmin: 'isAdmin',
      isEmployee: 'isEmployee',
      isOwner: 'isOwner',
    }),
  },

  created() {
    // Load current restaurant data
    this.load();
  },

  methods: {
    load() {
      this.loading = true;
      this.$store
        .dispatch('invoices/load', this.$route.params.restaurant)
        .then(response => {
          this.invoices = response.data;
        })
        .catch(error => {
          this.storeError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    forceFileDownload(response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'codigo_qr.png'); // or any other extension
      document.body.appendChild(link);
      link.click();
    },

    download(id) {
      // Descargar factura
      const payload = {
        restaurant: this.$route.params.restaurant,
        id: id,
      };

      this.$store
        .dispatch('invoices/download', payload)
        .then(response => {
          this.forceFileDownload(response);
        })
        .catch(e => this.storeError(e));
    },

    goToStripe(invoice) {
      window.open(invoice.hosted_invoice_url, '_blank');
    },

    reload() {
      this.dialog = false;
      this.load();
    },

    formatTimestamp(timestamp) {
      return dayjs.unix(timestamp).locale('es').format('dddd DD MMMM YYYY');
    },

    formatTimestampSmall(timestamp) {
      return dayjs.unix(timestamp).locale('es').format('DD/MM/YYYY');
    },
  },
};
</script>

export default {
  data: () => ({
    error: false,
    code: null,
    message: null,
    errors: [],
    cause: null,
  }),

  methods: {
    clearErrors() {
      this.error = false;
      this.code = null;
      this.message = null;
      this.cause = null;
    },

    storeError(error) {
      this.error = true;
      this.cause = error;

      // If we get a network error there is no response object, we need to store the
      // message from the error itself.
      if (error.response) {
        this.code = error.response.status;
        this.message = 'Los datos enviados no son válidos.';

        if (error.response.data.errors) {
          this.errors = error.response.data.errors;
        }

        if (error.response.data.message) {
          this.message = error.response.data.message;
        }
      } else {
        // Network error here
        this.code = 410;
        this.message = error.message;
      }
    },
  },
};
